'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Modal,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Stack,
  Spacer,
  Button,
  Link,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiChevronDown,
  FiShare2,
} from 'react-icons/fi'
import {
  IoChatboxOutline
} from 'react-icons/io5'
import {
  BiSpreadsheet
} from 'react-icons/bi'
import { IconType } from 'react-icons'
import Chatbots from '../pages/Chatbots'
import { useAuthContext } from '../hooks/useAuthContext.js'
import { useParams } from 'react-router-dom'
import { usePublicFetch } from '../hooks/usePublicFetch.js'
import { useEffect, useState } from 'react'
import PricingModal from './PricingModal'


const SidebarContent = ({ active, botname, data, page, onClose, ...rest }) => {

  const { id } = useParams();
  const {user} = useAuthContext();

  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

  const [showAllStats, setShowAllStats] = useState(false);
    console.log(data)

  const LinkItems = [
  ]
  
  const ManageLinkItems = [
    { name: 'Test Bot', iden: 'test', icon: IoChatboxOutline, path: `/manage/${id}/` },
    { name: 'Share Bot', iden: 'share', icon: FiShare2, path: `/manage/${id}/share` },
    { name: 'Monitor Bot', iden: 'sheet', icon: BiSpreadsheet, path: `/manage/${id}/sheet` },
    { name: 'Bot Settings', iden: 'settings', icon: FiSettings, path: `/manage/${id}/settings` },
  ]

  const AnalysisLinkItems = [
    { name: 'Analyze Data', iden: 'test', icon: IoChatboxOutline, path: `/manage/${id}/` },
    { name: 'Share Bot', iden: 'share', icon: FiShare2, path: `/manage/${id}/share` },
    { name: 'Monitor Bot', iden: 'sheet', icon: BiSpreadsheet, path: `/manage/${id}/sheet` },
    { name: 'Bot Settings', iden: 'settings', icon: FiSettings, path: `/manage/${id}/settings` },
  ]


  return (
<Box
  bg="white"
  borderRight="1px"
  borderRightColor={useColorModeValue('gray.200', 'gray.700')}
  w={{ base: 'full', md: 60 }}
  pos="fixed"
  height={`calc(100vh - 5rem)`}
  {...rest}
>
  <Flex h={{ base: '20', md: '5' }} alignItems="center" mx="8" justifyContent="space-between">
    <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
  </Flex>

  {page !== "manage" &&
  <Flex p="4" pt="0" flexDir="column">
  {user.data._doc.plan.includes("ASTier") ? (
    <>
      {/* Display this if the user's plan includes "ASTier..." */}
      <Text fontSize="lg" fontWeight="bold" color="#38a169">👋 Hey Sumo-ling!</Text>
      <Text color="gray.500" pt="2">
        Check out our new{" "}
        <Link
          href="https://workspace.google.com/marketplace/app/botsheets_ai_report_generator/401292491579"
          isExternal
          color="blue.500"
          fontWeight="bold"
        >
          Google Extension
        </Link>{" "}
        that instantly turns Google Sheets™ into Google Slides™ and Docs™. Ideal for agencies to quickly present clients with data-driven updates and insights.
      </Text>
    </>
  ) : (
    <>
      {/* Display this if the user's plan does NOT include "ASTier..." */}
      <Text fontSize="lg" fontWeight="bold" color="#38a169">💡 What's New?</Text>
      <Text color="gray.500" pt="2">
        Check out our new{" "}
        <Link
          href="https://workspace.google.com/marketplace/app/botsheets_ai_report_generator/401292491579"
          isExternal
          color="blue.500"
          fontWeight="bold"
        >
          Google Sheets Extension
        </Link>{" "}
        that instantly turns Google Sheets™ into Google Slides™ and Docs™. Ideal for agencies to quickly present clients with data-driven updates and insights.
      </Text>
    </>
  )}
</Flex>
  }
  
  
  {botname && page == "manage" && (
      <Flex align = "center" fontWeight = "bold" pb = "4" fontSize = "lg" flexDir = "column">
        <Text whiteSpace = "nowrap" maxWidth = "85%" overflow = "hidden" textOverflow = "ellipsis" color = "#38a169" pt = "2" >{botname}</Text>
      </Flex>
  )}

  {/* Navigation items */}
  <Box overflowY="auto" pb="3rem">
  {page === "manage" &&
  (data && data.type !== "analysis"
    && ManageLinkItems.map((link) => (
        <NavItem  
          path={link.path}
          key={link.name}
          icon={link.icon}
          iden={link.iden}
          active={active}
        >
          <Text fontWeight="semibold">{link.name}</Text>
        </NavItem>
      ))
    )}

{page === "manage" &&
  (data && data.type === "analysis"
    && AnalysisLinkItems.map((link) => (
      <NavItem  
        path={link.path}
        key={link.name}
        icon={link.icon}
        iden={link.iden}
        active={active}
      >
        <Text fontWeight="semibold">{link.name}</Text>
      </NavItem>
    ))
    )}


    {page != "manage" &&
      LinkItems.map((link) => (
        <NavItem path={link.path} key={link.name} icon={link.icon}>
          <Text fontWeight = "semibold">{link.name}</Text>
        </NavItem>
      ))}
  </Box>

  {/* Buttons aligned at the bottom */}
  <Flex
    justifyContent="center"
    position="absolute"
    bottom="0"
    left="0"
    right="0"
    p="1rem"
    flexDir = "column"
    alignItems = "center"
  >
    <Button onClick={onOpen} fontSize = "xs" mb = "3" mx="2" w = "100%" color="#495469">{user.data._doc.botCount}/{user.data.limits.botCount} Chatbots</Button>
    <Button onClick={onOpen} fontSize = "xs" mb = "3" mx="2" w = "100%" color="#495469">{user.data._doc.chatCount}/{user.data.limits.chatCount} Chat Credits</Button>
    {!showAllStats && <Button onClick={onOpen} fontSize = "xs" mb = "2" mx="2" w = "100%" fontWeight = "bold" color="#495469">More Usage...</Button>}
    {showAllStats && (<><Button onClick={onOpen} fontSize = "sm" mb = "3" mx="2" w = "100%" color="#027fcb">{user.data._doc.writeCount}/{user.data.limits.writeCount} Write Rows</Button>
    <Button onClick={onOpen} fontSize = "sm" mb = "3"  mx="2" w = "100%" color="#027fcb">{user.data._doc.charCount}/{user.data.limits.charCount} Doc Characters</Button></>)}
    {showAllStats && <Button onClick={() => setShowAllStats(!showAllStats)} fontSize = "sm" mb = "2" mx="2" w = "100%" fontWeight = "bold" color="#38a169">Hide</Button>}
    
    <Flex>
      <iframe src="https://status.botsheets.com/badge?theme=light" width="200" height="30" frameborder="0" scrolling="no"></iframe>
    </Flex>
    
  </Flex>
    <PricingModal user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal} />
</Box>
  )
}

const NavItem = ({ iden, active, path, icon, children, ...rest }) => {

  return (
        <Box
      as="a"
      href={path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mb="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={iden === active ? 'gray.600' : ''}
        color={iden === active ? 'white' : 'gray.700'}
        _hover={{
          bg: 'gray.600',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

const MobileNav = ({ onOpen, ...rest }) => {

  const { id } = useParams();
  const {user} = useAuthContext();
  const email = user.data._doc.email;
  console.log(user)
  const { isOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure()

  const signout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  }

  const getBillingLink = () => {
    fetch(`${process.env.REACT_APP_API_URL}/subscription/`, {
      method: 'GET',
      headers: {'Authorization': `Bearer ${user.data.token}`},
    }).then((res) => {
      res.json().then((data) => {
        window.location.href = data.session.url;
      })
    }
    )
  }


  return (
    <Flex
    pl={{ base: 4, md: 4 }}
    height="5rem"
    alignItems="center"
    bg="#139f5c"
    borderBottomWidth="1px"
    borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
    justifyContent={{ base: 'space-between', md: 'flex' }}
    {...rest}
    position="sticky"   // Add this line
    top="0"             // Add this line
    zIndex="100"        // Add this line
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        color = "white"
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Link href='/'>
      <Image 
        pl = "2"
        height = "14"
        src="/imgs/botsheets-logo.png"
      />
      </Link>
      <Spacer />
      <Text fontWeight = "extrabold" mr = "5" color = "white" ><Link href='/'>My Dashboard</Link></Text>
      <Text fontWeight = "extrabold" mr = "5" color = "white" ><Link href={onOpenModal} onClick={onOpenModal}>Upgrade</Link></Text>

      <HStack px = {{ base: '0', md: '4' }} h = "100%" bgColor = "green.700" spacing={{ base: '0', md: '6' }}>
        <Flex  alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text color = "white" >{email}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown color = "white" />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={onOpenModal}>Plans & Pricing</MenuItem>
              <MenuItem onClick = {getBillingLink}>My Billing</MenuItem>
              <a href="https://support.botsheets.com/" target="_blank" rel="noopener noreferrer">
                <MenuItem>Help &amp; Support</MenuItem>
              </a>
              <a href="https://www.botsheets.com/privacy" target="_blank" rel="noopener noreferrer">
                <MenuItem>Privacy</MenuItem>
              </a>
              <MenuDivider />
              <MenuItem onClick = {signout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <PricingModal user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
    </Flex>
  )
}

const SidebarWithHeader = ({active, page, component: Component}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  console.log(active)

  const { id } = useParams();
  const {user} = useAuthContext();
  const [botname, setBotname] = useState('');

  const { data, isPending, error } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  useEffect(() => {
    if (data) { 
      sessionStorage.setItem(id, data.title);
      setBotname(data.title);
    }
  }, [data])

  useEffect(() => {
    if (id) {
      setBotname(sessionStorage.getItem(id));
    }
  }, [id])

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <MobileNav onOpen={onOpen} />
      {page != "create" && <SidebarContent active = {active} botname = {botname} data = {data} page = {page} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent active = {active} botname = {botname} data = {data} page = {page} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      
      {page != "create" && (
        <Box ml={{ base: 0, md: 60 }}>
          <Component />
        </Box>
      )}

      {page == "create" && (
        <Box m={{ base: 0, md: 2 }}>
        <Component />
      </Box>
      )}
      
    </Box>
  )
}

export default SidebarWithHeader