import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
  Switch,
  Text,
  VStack,
  useColorModeValue,
  Code,
} from "@chakra-ui/react";

const SlideTesting = () => {
  const [presentationId, setPresentationId] = useState("");
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState({
    BOTSHEETS_TITLE: false,
    BOTSHEETS_TITLE_AND_BODY: false,
    BOTSHEETS_TITLE_AND_TWO_COLUMNS: false,
  });
  const [includeWatermark, setIncludeWatermark] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelectedSlides((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handlePasswordSubmit = () => {
    if (password === "BOTSHEETS123") {
      setIsAuthenticated(true);
      setPassword("");
    } else {
      setErrorMessage("Incorrect password");
    }
  };

  const handleSubmit = async () => {
    if (!isAuthenticated) {
      setErrorMessage("Please authenticate first");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");
    setServerResponse(null);

    try {
      const response = await fetch(
      //  "https://content-gen.botsheets.com/api/content/testPresentation",
        "http://localhost:3000/api/content/testPresentation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            presentationId,
            slides: selectedSlides,
            watermark: includeWatermark,
            password: "BOTSHEETS123",
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setErrorMessage(data.error || "An unexpected error occurred.");
      } else {
        setServerResponse(data);
      }
    } catch (error) {
      setErrorMessage("Network error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthenticated) {
    return (
      <Box
        minH="100vh"
        bg={useColorModeValue("gray.50", "gray.900")}
        display="flex"
        alignItems="center"
        justifyContent="center"
        p={4}
      >
        <Container
          maxW="lg"
          bg={useColorModeValue("white", "gray.800")}
          boxShadow="md"
          borderRadius="md"
          p={8}
        >
          <VStack spacing={6} align="stretch">
            <Heading textAlign="center" size="lg">
              Authentication Required
            </Heading>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handlePasswordSubmit();
                  }
                }}
              />
            </FormControl>
            <Button colorScheme="blue" onClick={handlePasswordSubmit}>
              Submit
            </Button>
            {errorMessage && (
              <Text color="red.500" fontWeight="bold">
                {errorMessage}
              </Text>
            )}
          </VStack>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue("gray.50", "gray.900")}
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Container
        maxW="lg"
        bg={useColorModeValue("white", "gray.800")}
        boxShadow="md"
        borderRadius="md"
        p={8}
      >
        <VStack spacing={6} align="stretch">
          <Heading textAlign="center" size="lg">
            Slide Testing
          </Heading>

          <Text>
            Enter a <strong>copied presentation ID</strong> of the original template
            and not the <strong>original template ID</strong>, otherwise your template may be deleted!
          </Text>

          <Text>
            Make sure the presentation is set to <strong>publicly editable</strong>. This adds slides to the end of the existing presentation.
          </Text>

          <Text>
            Testing Sheet: <a href="https://docs.google.com/spreadsheets/d/16eP2Rv4VRfa5ASveBH-T9dJ9qKagbg2h9ibyDbe1Q1o/edit?usp=sharing" target="_blank">https://docs.google.com/spreadsheets/d/16eP2Rv4VRfa5ASveBH-T9dJ9qKagbg2h9ibyDbe1Q1o/edit?usp=sharing</a>
          </Text>

          <FormControl>
            <FormLabel>Copied Presentation ID</FormLabel>
            <Input
              placeholder="Enter the presentation ID here"
              value={presentationId}
              onChange={(e) => setPresentationId(e.target.value)}
            />
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel mb="0">Include watermark?</FormLabel>
            <Switch
              isChecked={includeWatermark}
              onChange={(e) => setIncludeWatermark(e.target.checked)}
            />
          </FormControl>

          <Button colorScheme="blue" onClick={handleSubmit} disabled={!presentationId || isLoading}>
            {isLoading ? <Spinner size="sm" /> : "Submit"}
          </Button>

          {errorMessage && (
            <Text color="red.500" fontWeight="bold">
              {errorMessage}
            </Text>
          )}

          {serverResponse && (
            <Box
              border="1px solid"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              rounded="md"
              p={4}
              mt={4}
            >
              <Heading as="h3" size="md" mb={2}>
                Server Response
              </Heading>
              <Code
                display="block"
                whiteSpace="pre"
                p={2}
                w="100%"
                children={JSON.stringify(serverResponse, null, 2)}
              />
            </Box>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default SlideTesting;
