import { Button, Link, Card, FormControl, useDisclosure, FormLabel, Textarea, Select, Checkbox, Icon, CardBody, IconButton, HStack, Spacer, Grid, GridItem, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../../DeleteBotButton'
import LoadingBox from '../../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../../hooks/usePublicFetch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaTrash } from 'react-icons/fa'
import UpgradeModal from '../../UpgradeModal';


const DataPrompt = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [formData, setFormData] = useState({});
    const [prompt, setPrompt] = useState('');

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSaveClick = () => {


        if (!formData.welcomeMessage) {
            alert('Please fill out all fields');
            return;
        }

        if (formData.leadingQuestion === 'Yes' && !formData.additionalWelcomeMessage) {
            alert('You indicated that you want to ask a leading question, but you did not provide one. Please provide one.');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'rules': formData, 'answer_prompt': prompt})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })
            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    const handlePromptClick = () => {
        if (user.data._doc.plan === 'free') {
            onOpen();
        }
    }

    useEffect(() => {
        if (data) {
            setFormData(data.rules);
            setPrompt(data.answer_prompt);
        }
    }, [data])


    if (isPending) {
    return <div>Loading Chatbot Info...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box mt = "1" bgColor = "white" w = "100%" borderRadius = "5" p = "3">
        
    <form>

      <Box mb={4}>
          <FormControl>
            <FormLabel>
            <Heading size='md' fontWeight = "bold" color = "#38a169">Base Prompt</Heading>
            <Text mb = "5" color = "grey.500" fontSize="sm"><Link href="https://support.botsheets.com/docs/base-prompt" isExternal color='#1772cc'>Guide your chatbot</Link> on your goal and modify rules to prompt and influence the behavior of your chatbot. Max 2000 characters.</Text>
            </FormLabel>
            <Textarea
              maxLength = "2500"
              h = "300px"
              name="prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onClick = {handlePromptClick}
              readOnly={user.data._doc.plan === 'free'}
              backgroundColor = "gray.100"
              borderColor = "gray.300"
              fontSize="sm"
            />
          </FormControl>
          <Text fontSize="sm" fontWeight = "bold" color = "#38a169"> Include &#123;tone&#125; in the Base Prompt to maintain the selected tone in the Basic Rules tab.</Text>
        </Box>

    </form>

        <HStack mt = "5">
            <Spacer />
            <Button colorScheme = "green" onClick = {handleSaveClick}>Save Changes</Button>
        </HStack>

        <UpgradeModal text = {"Upgrade Plans To Edit Prompt"} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
    </Box>
  )
}

export default DataPrompt