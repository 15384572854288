import React from 'react';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

function ScrollableTable({ data }) {

  const headers = data[0] ? Object.keys(data[0]).map(key => ({ field: key })) : [];

  const pagination = true;
  const paginationPageSize = 25;
  const paginationPageSizeSelector = [10, 25, 50];

  return (
    // wrapping container with theme & size
    <div
     className="ag-theme-quartz" // applying the grid theme
     style={{ height: 400 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
          rowData={data}
          columnDefs={headers}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          rowSelection='multiple'
          rowMultiSelectWithClick={true}
      />
    </div>
   )
}

export default ScrollableTable;
