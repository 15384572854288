import React, { useEffect, useState } from "react";
import { 
  Box, 
  Spinner, 
  Text, 
  Heading, 
  VStack, 
  Link, 
  Button, 
  Container, 
  useColorModeValue 
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";

const InvitationAccept = () => {
  const [status, setStatus] = useState("loading");
  const [errorMessage, setErrorMessage] = useState("");
  const [showInstructions, setShowInstructions] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    const acceptInvitation = async () => {
      if (!token) {
        setStatus("error");
        setErrorMessage("Invitation link is invalid - missing token.");
        return;
      }

      try {
        const response = await fetch(
          "https://content-gen.botsheets.com/api/content/acceptInvite",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          setStatus("error");
          setErrorMessage(data.error || "An unexpected error occurred");
          return;
        }

        setStatus("success");
      } catch (error) {
        setStatus("error");
        setErrorMessage("Network error occurred. Please try again later.");
      }
    };

    acceptInvitation();
  }, [token]);

  return (
    <Box 
      minH="100vh"
      bg={useColorModeValue("gray.50", "gray.900")}
      display="flex" 
      alignItems="center" 
      justifyContent="center"
      px={4}
    >
      <Container 
        maxW="lg" 
        bg={useColorModeValue("white", "gray.800")} 
        boxShadow="md" 
        borderRadius="md" 
        p={8}
      >
        <VStack spacing={6} textAlign="center">
          <Heading size="lg">
            {status === "loading" ? "Accepting Invitation..." : 
             status === "error" ? "Invitation Error" : 
             "Invitation Accepted!"}
          </Heading>

          {status === "loading" && (
            <>
              <Spinner size="xl" color="blue.500" />
              <Text>We’re activating your license. Please wait…</Text>
            </>
          )}

          {status === "error" && (
            <>
              <Text color="red.500" fontWeight="bold">
                {errorMessage}
              </Text>
              <Text>
                If you believe this is a mistake, please contact your team administrator
                or our support team for assistance.
              </Text>
              <Button
                colorScheme="blue"
                onClick={() => window.location.href = "https://support.botsheets.com/"}
              >
                Contact Support
              </Button>
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={() => setShowInstructions(!showInstructions)}
              >
                {showInstructions ? 'Hide' : 'View'} Installation Instructions
              </Button>
              {showInstructions && (
                <Box
                  border="1px solid"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  rounded="md"
                  p={4}
                  w="100%"
                  textAlign="left"
                >
                  <Heading as="h2" size="md" mb={2}>
                    How to Access Botsheets
                  </Heading>
                  <Text mb={2}>
                    1. Install our 
                    <Link 
                      color="blue.500" 
                      href="https://chrome.google.com/webstore" 
                      isExternal 
                      ml={1}
                    >
                      Google Sheets Add-On
                    </Link>
                    .
                  </Text>
                  <Text mb={2}>
                    2. Once installed, open a spreadsheet and click on the Botsheets add-on from the toolbar.
                  </Text>
                  <Text>
                    3. Your plan should be automatically activated, and you'll be able to get started right away!
                  </Text>
                </Box>
              )}
            </>
          )}

          {status === "success" && (
            <>
              <Text color="green.500" fontWeight="bold">
                Success! You have joined the team.
              </Text>
              <Text>
                Next steps: You can now access your team features. 
                Below you'll find instructions on how to use our Google Sheets extension, Botsheets.
              </Text>
              <Box
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                rounded="md"
                p={4}
                w="100%"
                textAlign="left"
              >
                <Heading as="h2" size="md" mb={2}>
                  How to Access Botsheets
                </Heading>
                <Text mb={2}>
                  1. Install our 
                  <Link 
                    color="blue.500" 
                    href="https://chrome.google.com/webstore" 
                    isExternal 
                    ml={1}
                  >
                    Google Sheets Add-On
                  </Link>
                  .
                </Text>
                <Text mb={2}>
                  2. Once installed, open a spreadsheet and click on the Botsheets add-on from the toolbar.
                </Text>
                <Text>
                  3. Your plan should be automatically activated, and you'll be able to get started right away!
                </Text>
              </Box>

              <Button 
                colorScheme="blue"
                onClick={() => alert("This would direct users to the next step.")}
              >
                Install Add-On
              </Button>
            </>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default InvitationAccept;
