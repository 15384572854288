import React, { useCallback, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  Flex,
  Switch,
  useColorModeValue,
  Badge,
  Input,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
import "./ModalStyles.css";

const stripePromise = loadStripe("pk_live_AvS4MVoCMFZWbZuju3jTnj2e");

const ContentPricing = () => {
  const [billingCycle, setBillingCycle] = useState("monthly"); // Default to yearly
  const [clientSecret, setClientSecret] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false); // Toggle for multiple licenses
  const [quantity, setQuantity] = useState(1); // Default quantity is 1

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const { onOpen, onClose } = {
    onOpen: () => setModalOpen(true),
    onClose: () => setModalOpen(false),
  };

  const pricingTiers = {
    monthly: {
      lite: [
        { max: 1, unitPrice: 9.0 },
        { max: 10, unitPrice: 8.5 },
        { max: 100, unitPrice: 8.0 },
        { max: Infinity, unitPrice: 7.75 },
      ],
      pro: [
        { max: 1, unitPrice: 29.0 },
        { max: 10, unitPrice: 28.5 },
        { max: 100, unitPrice: 28.0 },
        { max: Infinity, unitPrice: 27.75 },
      ],
    },
    yearly: {
      lite: [
        { max: 1, unitPrice: 90.0 },
        { max: 10, unitPrice: 87.50 },
        { max: 100, unitPrice: 85.0 },
        { max: Infinity, unitPrice: 82.50 },
      ],
      pro: [
        { max: 1, unitPrice: 290.0 },
        { max: 10, unitPrice: 285.0 },
        { max: 100, unitPrice: 280.0 },
        { max: Infinity, unitPrice: 277.5 },
      ],
    },
  };

  const calculateTieredPrice = (planType, quantity) => {
    const tiers = pricingTiers[billingCycle][planType];
    let total = 0;
    let remaining = quantity;

    for (const tier of tiers) {
      if (remaining <= 0) break;

      const applicableQuantity = Math.min(
        remaining,
        tier.max - (tiers[tiers.indexOf(tier) - 1]?.max || 0)
      );

      total += applicableQuantity * tier.unitPrice;
      remaining -= applicableQuantity;
    }

    return total.toFixed(2);
  };

  const handleQuantityChange = (value) => {
    if (value >= 1) setQuantity(value);
  };

  const fetchClientSecret = useCallback(
    async (plan) => {
      if (!email) {
        console.error("Email parameter is missing in the URL.");
        return;
      }

      const body = {
        email,
        plan,
        billingCycle,
        quantity,
      };

      const response = await fetch(
        "https://content-gen.botsheets.com/api/content/checkout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await response.json();
      setClientSecret(data.clientSecret);
      onOpen(); // Open the modal
    },
    [billingCycle, email, quantity, onOpen]
  );

  const cardBg = useColorModeValue("gray.100", "gray.700");
  const popularBorderColor = useColorModeValue("gray.500", "gray.300");

  return (
    <VStack px={6}>
      {/* Billing Cycle Toggle */}
      <Flex align="center" mb={8}>
        <Text fontSize="lg" mr={2}>
          Monthly
        </Text>
        <Switch
          size="lg"
          isChecked={billingCycle === "yearly"}
          onChange={(e) =>
            setBillingCycle(e.target.checked ? "yearly" : "monthly")
          }
        />
        <Flex align="center" ml={2}>
          <Text fontSize="lg">Yearly</Text>
          <Badge ml={2} colorScheme="green" fontSize="xs">
            Save +16%
          </Badge>
        </Flex>
      </Flex>

      {/* Pricing Cards */}
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        gap={6}
        pt={6}
      >
        {/* Lite Plan */}
<Box
  bg={cardBg}
  p={6}
  rounded="lg"
  boxShadow="lg"
  width={{ base: "90%", md: "300px" }}
>
  <Heading size="md" mb={4}>
    Botsheets Extension - Lite
  </Heading>
  <Flex align="baseline" mb={4}>
    <Text fontSize="3xl" fontWeight="bold">
      ${calculateTieredPrice("lite", quantity)}
    </Text>
    <Text fontSize="sm" color="gray.500" ml={2}>
      {billingCycle}
    </Text>
  </Flex>
  <Text fontSize="sm" color="gray.600" mb={2}>
      Includes:
    </Text>
  {billingCycle === "yearly" && (
    <Text fontSize="sm" color="green.500" mt={2}>
      Yearly Discount: -$
      {(
        quantity *
          ((pricingTiers["monthly"].lite[0].unitPrice * 12) -
            pricingTiers["yearly"].lite[0].unitPrice)
      ).toFixed(2)}
    </Text>
  )}
  {isMultiple && (
    <Text fontSize="sm" color="green.500" mt={2}>
      Multi License Discount: -$
      {(
        quantity * pricingTiers[billingCycle].lite[0].unitPrice -
        calculateTieredPrice("lite", quantity)
      ).toFixed(2)}
    </Text>
  )}
  <Box mt={2}>
    <VStack align="start" spacing={1}>
      <Text fontSize="sm">25 Generations Per Month</Text>
      <Text fontSize="sm">Create Slides and Docs</Text>
      <Text fontSize="sm">Unlock All Presentation Themes</Text>
      <Text fontSize="sm">Removed Botsheets Branding</Text>
      <Text fontSize="sm">Email Support</Text>
    </VStack>
  </Box>
  {isMultiple && (
  <Flex align="center" mt={2}>
    <IconButton
      aria-label="Decrease quantity"
      icon={<MinusIcon />}
      size="sm"
      onClick={() => handleQuantityChange(quantity - 1)}
      isDisabled={quantity <= 2}
    />
    <Input
      type="number"
      value={quantity}
      min={2}
      width="60px"
      mx={2}
      textAlign="center"
      onChange={(e) =>
        handleQuantityChange(Number(e.target.value.replace(/^0/, "")))
      }
    />
    <IconButton
      aria-label="Increase quantity"
      icon={<AddIcon />}
      size="sm"
      onClick={() => handleQuantityChange(quantity + 1)}
    />
  </Flex>
    )}
  <Button mt={4} colorScheme="blue" width="full" onClick={() => fetchClientSecret("lite")}>
    Upgrade
  </Button>
</Box>

{/* Pro Plan */}
<Box
  border="2px"
  borderColor={popularBorderColor}
  p={6}
  rounded="lg"
  boxShadow="lg"
  width={{ base: "90%", md: "300px" }}
  position="relative"
>
  <Badge
    position="absolute"
    top={-3}
    left={4}
    colorScheme="blue"
    rounded="md"
    px={2}
    py={1}
    fontSize="xs"
  >
    Most Popular
  </Badge>
  <Heading size="md" mb={4}>
    Botsheets Extension - Pro
  </Heading>
  <Flex align="baseline" mb={4}>
    <Text fontSize="3xl" fontWeight="bold">
      ${calculateTieredPrice("pro", quantity)}
    </Text>
    <Text fontSize="sm" color="gray.500" ml={2}>
      {billingCycle}
    </Text>
  </Flex>
  <Text fontSize="sm" color="gray.600" mb={2}>
      Includes:
    </Text>
  {billingCycle === "yearly" && (
    <Text fontSize="sm" color="green.500" mt={2}>
      Yearly Discount: -$
      {(
        quantity *
          ((pricingTiers["monthly"].pro[0].unitPrice * 12) -
            pricingTiers["yearly"].pro[0].unitPrice)
      ).toFixed(2)}
    </Text>
  )}
  {isMultiple && (
    <Text fontSize="sm" color="green.500" mt={2}>
      Multi License Discount: -$
      {(
        quantity * pricingTiers[billingCycle].pro[0].unitPrice -
        calculateTieredPrice("pro", quantity)
      ).toFixed(2)}
    </Text>
  )}
  <Box mt={2}>

    <VStack align="start" spacing={1}>
    <Text fontSize="sm">100 Generations Per Month</Text>
      <Text fontSize="sm">Create Slides and Docs</Text>
      <Text fontSize="sm">Unlock All Presentation Themes</Text>
      <Text fontSize="sm">Removed Botsheets Branding</Text>
      <Text fontSize="sm">Priority Email Support</Text>
    </VStack>
  </Box>
  {isMultiple && (
  <Flex align="center" mt={2}>
    <IconButton
      aria-label="Decrease quantity"
      icon={<MinusIcon />}
      size="sm"
      onClick={() => handleQuantityChange(quantity - 1)}
      isDisabled={quantity <= 2}
    />
    <Input
      type="number"
      value={quantity}
      min={2}
      width="60px"
      mx={2}
      textAlign="center"
      onChange={(e) =>
        handleQuantityChange(Number(e.target.value.replace(/^0/, "")))
      }
    />
    <IconButton
      aria-label="Increase quantity"
      icon={<AddIcon />}
      size="sm"
      onClick={() => handleQuantityChange(quantity + 1)}
    />
  </Flex>
  )}
  <Button mt={4} colorScheme="blue" width="full" onClick={() => fetchClientSecret("pro")}>
    Upgrade
  </Button>
</Box>

      </Flex>

      {/* Modal for Embedded Checkout */}
      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="modal-close-button" onClick={onClose}>
              &times;
            </button>
            <div className="modal-body">
              {clientSecret && (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{ clientSecret }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Toggle for multiple licenses */}
      <Tooltip
        label={!isMultiple ? "License keys and instructions will be sent to your email" : "License will be automatically applied to your account"}
      >
        <Text
          fontSize="sm"
          color="blue.500"
          cursor="pointer"
          pt={4}
          textDecoration={"underline"}
          onClick={() => {
            setIsMultiple(!isMultiple);
            if (!isMultiple) {
              setQuantity(2);
            } else {
              setQuantity(1);
            }
          }}
        >
          {isMultiple ? "Just buying one license?" : "Buying 2+ licenses?"}
        </Text>
      </Tooltip>
    </VStack>
  );
};

export default ContentPricing;
