import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import "./ModalStyles.css";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const plan = searchParams.get("plan");
  const quantity = parseInt(searchParams.get("quantity"), 10);

  return (
    <VStack px={6} textAlign="center" spacing={6}>
      <Heading mt={6} mb={6}>
        Payment Successful!
      </Heading>

      <Text>
        Thank you for your purchase! Your subscription has been successfully activated.
      </Text>

      {email && plan && (
        <Text mt={4}>
          <strong>Email:</strong> {email}
          <br />
          <strong>Plan:</strong> {plan.charAt(0).toUpperCase() + plan.slice(1)}
        </Text>
      )}

      <Text mt={4}>
        Your subscription has been automatically applied to <strong>{email}</strong>. You may need to refresh the extension and wait 1-2 for the changes to take effect.
      </Text>

      <Text mt={6}>
        Need assistance or have questions? We’re here to help! Reach out to us anytime at&nbsp;
        <Link href="mailto:support@botsheets.com" color="blue.500">
          support@botsheets.com
        </Link>.
      </Text>

      <Text>
        We truly appreciate your trust in Botsheets. If you have any feedback or suggestions, we’d love to hear from you!
      </Text>
    </VStack>
  );
};

export default PaymentSuccess;
