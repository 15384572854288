// Import necessary libraries
import React, { useState } from 'react';
import { Box, VStack, Button, Text, Heading, HStack, Divider } from '@chakra-ui/react';
import ContentPricing from './ContentPricing';

// Example Content Components
const ContentTeam = () => <Text>Manage Your Team Content</Text>;
const ContentSupport = () => <Text>Help and Support Content</Text>;
const ContentNew = () => <Text>Create New Content</Text>;
const ContentThemes = () => <Text>Themes Content</Text>;
const ContentDatasets = () => <Text>Example Datasets Content</Text>;
const ContentTutorial = () => <Text>Tutorial Content</Text>;

// Sidebar Component
const Sidebar = ({ onSelect, selectedComponent }) => {
  return (
    <Box
      width={{ base: 'full', md: '300px' }}
      bg="white"
      borderRight="1px solid #e2e8f0"
      minH="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      padding="4"
    >
      <VStack spacing={4} align="stretch">
        <Box textAlign="left">
          <Heading as="h2" size="md">
            Botsheets
          </Heading>
          <Text fontSize="sm" color="gray.500">
            josh@janis.ai
          </Text>
        </Box>

        <VStack align="stretch" spacing={2} mt={6}>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            color={selectedComponent === 'createNew' ? 'red.500' : 'inherit'}
            onClick={() => onSelect('createNew')}
          >
            Create New
          </Button>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            color={selectedComponent === 'themes' ? 'red.500' : 'inherit'}
            onClick={() => onSelect('themes')}
          >
            Generations
          </Button>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            color={selectedComponent === 'datasets' ? 'red.500' : 'inherit'}
            onClick={() => onSelect('datasets')}
          >
            Example Datasets
          </Button>
          <Button
            variant="ghost"
            justifyContent="flex-start"
            color={selectedComponent === 'tutorial' ? 'red.500' : 'inherit'}
            onClick={() => onSelect('tutorial')}
          >
            Tutorial
          </Button>
        </VStack>

        <Box bg="gray.100" borderRadius="md" p={4} mt={6}>
          <Heading as="h3" size="xs" mb={2}>
            Turn spreadsheets into chatbots!
          </Heading>
          <Button
            bg="brown"
            color="white"
            width="full"
            _hover={{ bg: 'darkbrown' }}
            onClick={() => window.open('https://chat.botsheets.com', '_blank')}
          >
            Learn More
          </Button>
        </Box>
      </VStack>

      <VStack align="stretch" spacing={2}>
        <Divider />
        <Button
          variant="ghost"
          justifyContent="flex-start"
          color={selectedComponent === 'pricing' ? 'red.500' : 'inherit'}
          onClick={() => onSelect('pricing')}
        >
          Plans and billing
        </Button>
        <Button
          variant="ghost"
          justifyContent="flex-start"
          color={selectedComponent === 'team' ? 'red.500' : 'inherit'}
          onClick={() => onSelect('team')}
        >
          Manage your team
        </Button>
        <Button
          variant="ghost"
          justifyContent="flex-start"
          color={selectedComponent === 'support' ? 'red.500' : 'inherit'}
          onClick={() => onSelect('support')}
        >
          Help and support
        </Button>
      </VStack>
    </Box>
  );
};

// Mapping Components
const componentMapping = {
  createNew: ContentNew,
  themes: ContentThemes,
  datasets: ContentDatasets,
  tutorial: ContentTutorial,
  pricing: ContentPricing,
  team: ContentTeam,
  support: ContentSupport,
};

// MainContent Component
const MainContent = ({ selected }) => {
  const SelectedComponent = componentMapping[selected] || (() => <Text>Not Found</Text>);
  return (
    <Box flex="1" padding="4">
      <Heading size="lg">{selected}</Heading>
      <Box mt={4}>
        <SelectedComponent />
      </Box>
    </Box>
  );
};

// AppLayout Component
const AppLayout = () => {
  const [selectedComponent, setSelectedComponent] = useState('createNew');

  const handleSelect = (component) => {
    setSelectedComponent(component);
  };

  return (
    <HStack spacing={0} align="stretch" height="100vh">
      <Sidebar onSelect={handleSelect} selectedComponent={selectedComponent} />
      <MainContent selected={selectedComponent} />
    </HStack>
  );
};

export default AppLayout;
